import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import MemberCard from './components/MemberCard/MemberCard';
import SideBar from './components/SideBar/SideBar';
import MemberPage from './components/MemberPage/MemberPage';
import EventPage from './components/EventPage/EventPage';
import LogInpage from './components/LogInpage/LogInpage';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import DonationPage from './components/DonationPage/DonationPage';

function App() {
  const [isLogedIn, setisLogedIn] = useState(false)
  return (<Router>
        {isLogedIn? <div className="App">
          <SideBar onLogout={setisLogedIn}></SideBar>
          <Routes >
            <Route path="/" element={<MemberPage collection="members" />} />
            <Route path="/fmembers" element={<div><MemberPage collection="formMembers" /></div>} />
            <Route path="/events" element={<EventPage />} />
            <Route path="/donation" element={<DonationPage />} />
  
          </Routes >
        </div>:
      <LogInpage onLogin = {setisLogedIn}/>}
      </Router>
  );
}

export default App;
