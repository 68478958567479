// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import 'firebase/compat/storage';  
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC-aFTGOs_1xyllSjuMoFlBZlvMDYbnwTc",
  authDomain: "abjp-website.firebaseapp.com",
  projectId: "abjp-website",
  storageBucket: "abjp-website.appspot.com",
  messagingSenderId: "117251653816",
  appId: "1:117251653816:web:9535c9bc3ff96d398c344d",
  measurementId: "G-ZKE3SE55QZ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
export const db = firebase.firestore();
export const storage = firebase.storage();
// export const storage = firebase.storage();