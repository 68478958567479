import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LogInpage.css';
import { db } from '../../firebaseConfig';
import MD5 from "crypto-js/md5";

function LogInpage(props) {
  
  const [wongCreds, setwongCreds] = useState(false)
  const [username, setusername] = useState("")
  const [password, setpassword] = useState("")
  function onLogin(){
    db.collection('metadata').doc('admin').get().then((creds)=>{
      console.log(!(username===creds.data()["username"]&&MD5(password).toString()===creds.data()["password"]))
      setwongCreds(!(username===creds.data()["username"]&&MD5(password).toString()===creds.data()["password"]))
      props.onLogin(username===creds.data()["username"]&&MD5(password).toString()===creds.data()["password"])
    })
  }
  return (
    <div className="LogInpage">
      <div className='logInForm'>
        <h1>Log In</h1>
        <label style={wongCreds? {display:'block',color:'red'}: {display:'none',color:'red'}}>Wrong Credentials</label>
        <input placeholder='username' onChange={(e)=>{setusername(e.target.value)}}></input>
        <input placeholder='password' type='password' onChange={(e)=>{setpassword(e.target.value)}}></input>
        <button type='submit' onClick={onLogin}>Log In Now</button>
      </div>
    </div>
  );
}

LogInpage.propTypes = {};

LogInpage.defaultProps = {};

export default LogInpage;
