import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SideBar.css';
import logoImg from '../../assets/logo.png'
import { Link } from 'react-router-dom';

function SideBar(props) {

  function changeSection(sectionName) {
    props.setsection(sectionName)
    
  }

  return (
    <div className="SideBar">
      <div className='partyLogo'>
        <img src={logoImg} />
      </div>
      <div className='buttons'>
        <Link className='Link' to="/">Core Members</Link>
        <Link className='Link'  to="/fmembers">Form Members</Link>
        <Link className='Link' to="/events">Events</Link>
        <Link className='Link'  to="/donation">Donation</Link>
      </div>
      <div className='logout' onClick={()=>props.onLogout(false)}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7 12H21M21 12L18 9M21 12L18 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
        Logout</div>
    </div>
  );
}

SideBar.propTypes = {};

SideBar.defaultProps = {};

export default SideBar;
