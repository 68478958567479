import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './MemberCard.css';
import { db } from '../../firebaseConfig';
import { collection } from 'firebase/firestore';



function MemberCard(props) {
  const [MemebrName, setMemebrName] = useState(props.name)
  const [member, setmember] = useState({})


  function toggleShowPage(e){
  
    db.collection(props.collection).doc(props.docId).update({"onMainPage":e.target.checked})
  }

  
  function updateValue(e,key){
    let newName = e.target.value
    if (newName !=MemebrName) {
      db.collection(props.collection).doc(props.docId).update({[`${key}`]:newName})
      setMemebrName({
        name: newName,
        showOnPage: member.showOnPage,
        post: member.post,
        imgUrl: member.imgUrl,
        id: member.id})
    }
  }

  //Getting member from docID
  useEffect(() => {
  db.collection(props.collection).doc(props.docId).get().then((doc)=>{
    const mem = {
      name: doc.data()["name"],
      showOnPage: doc.data()["onMainPage"],
      post: doc.data()["post"],
      imgUrl: doc.data()["imgUrl"],
      id: doc.id
    }
    setmember(mem)

  })
  }, [])
  
  return (
    <div className="MemberCard">
      <div className='profile'>
        <img width={'60px'} height={'60px'} src={props.imgUrl}></img>
        <div className='candidateInfo'>
          <input className='candidateName' defaultValue={member.name} onBlur={(e)=>updateValue(e,"name")}></input>
          <input className='candidatePost' defaultValue={member.post} onBlur={(e)=>updateValue(e,"post")}></input>
          <div className='candidatePost' >{member.id}</div>
        </div>
      </div>
      <div className='actionBar'>
        <div className='buttonConatiner'>
          <label class="switch">
            <input type="checkbox" defaultChecked = {props.showOnPage} onChange={toggleShowPage}/>
            <span class="slider round"></span>
          </label>
          <div>Show On Page</div>
        </div>
        <div className='buttonConatiner'>
          <svg width="20" height="20" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23.2727" cy="22.7273" r="22.7273" fill="white" />
            <path d="M19.1816 18.6363V15.9091H27.3635V18.6363" stroke="#333333" stroke-width="1.81818" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.1819 26.8182H16.4546V23.1818C16.4546 21.6756 17.6756 20.4545 19.1819 20.4545H27.3637C28.8699 20.4545 30.091 21.6756 30.091 23.1818V26.8182H27.3637" stroke="#333333" stroke-width="1.81818" stroke-linecap="round" stroke-linejoin="round" />
            <rect x="19.1816" y="24.0909" width="8.18182" height="5.45455" stroke="#333333" stroke-width="1.81818" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div>Invoice</div>
        </div>
      </div>
    </div>
  );
}

MemberCard.propTypes = {};

MemberCard.defaultProps = {};

export default MemberCard;
