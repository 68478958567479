import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './EventBox.css';
import { db } from '../../firebaseConfig';

function EventBox(props) {

  const [Event, setEvent] = useState({})

  useEffect(() => {
    setEvent({
      title: props.title,
      discription: props.discription,
      date: props.date,
      imgUrl: props.imgUrl,
      docID: props.docID
    })
    console.log(props.discription)
  
  }, [])
  

  function updateValue(e,key){
    console.log(e)
    console.log("Function Called for: "+key+", "+e.target.value)
    console.log("Doc ID: "+props.docID)
    let newName = e.target.value
    if (key=='date') {
      newName = new Date(newName)
    }
    db.collection(props.collection).doc(props.docID).update({[`${key}`]:newName}).then((doc)=>{
      console.log(doc)
    })
  }
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return (
    <div className={"EventBox"}>
      <img style={{ backgroundImage: 'url('+props.imgUrl +')' }} />
      <div className='info'>
        <input className='eventTitle' onChange={(e)=>{updateValue(e,"title")}} defaultValue = {Event.title}></input>
        {console.log(props.date)}
        <input className='eventDate' onChange={(e)=>{updateValue(e,"date")}} type='datetime-local' defaultValue = {Event.date?Event.date.toDate().toISOString().slice(0, -1):null}></input>
        <textarea  className='eventDiscription' rows="4" cols="35" onChange={(e)=>{updateValue(e,"discription")}}>{props.discription}</textarea>
      </div>
    </div>
  );
}

EventBox.propTypes = {};

EventBox.defaultProps = {};

export default EventBox;
