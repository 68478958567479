import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './EventPage.css';
import { db, storage } from '../../firebaseConfig';
import EventBox from '../EventBox/EventBox';

function EventPage() {

  const [eventList, seteventList] = useState([])
  const [seachQuery, setseachQuery] = useState("")
  const [eventTitle, seteventTitle] = useState("")
  const [eventDisc, seteventDisc] = useState("")
  const [eventDate, seteventDate] = useState(null)
  const [userImgFile, setuserImgFile] = useState(null)
  const [showForm, setshowForm] = useState(false)
  const [eventImgUrl, seteventImgUrl] = useState("https://firebasestorage.googleapis.com/v0/b/abjp-website.appspot.com/o/home_bg.png?alt=media&token=751612a2-d03a-40df-8f6d-8541931d211c")


  useEffect(() => {
    db.collection('events').get().then((docs) => {
      var evlist = []
      docs.forEach(doc => {
        evlist.push({
          title: doc.data()["title"],
          discription: doc.data()["discription"],
          date: doc.data()["date"],
          imgUrl: doc.data()["imgUrl"],
          id: doc.id
        })
      });
      seteventList(evlist)
    })

  }, [])


  const readURL = event => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        seteventImgUrl(reader.result)
      }
    }
    if (event.target.files[0].size > 250000) {
      alert("Image is too big! Must be less Than 250kb");
      seteventImgUrl()
    }
    else {
      setuserImgFile(event.target.files[0])
      reader.readAsDataURL(event.target.files[0])
    }

  }
  function AddEvent(e) {
    e.preventDefault()

    if (userImgFile) {
      //If Imgae File is avaiblable then Upload Imge and then Upload data
      const uploadName = 'events/' + 'img' + (new Date()).getTime() + '.' + userImgFile.name.split('.').at(-1)
      const storageRef = storage.ref(uploadName)
      storageRef.put(userImgFile).on('state_changed', (snap) => {
      }, (err) => {
        alert(err.message)
      }
        , async () => {
          const url = await storageRef.getDownloadURL();
          seteventImgUrl(url)

          db.collection('metadata').doc('eventData').get().then((data) => {
            var counter = data.data()["eventCounter"]
            console.log()
            counter++
            var EVID = "EV" + ("0".repeat(3 - counter.toString().length)) + counter
            const newEV = { "title": eventTitle, "discription": eventDisc, "date": eventDate, "imgUrl": eventImgUrl }
            db.collection('events').doc(EVID).set(newEV).then(() => {
              db.collection("metadata").doc('eventData').set({ "eventCounter": counter }).then(() => { setshowForm(false) })


            })
          })
        })

    } else {


      db.collection('metadata').doc('eventData').get().then((data) => {
        var counter = data.data()["eventCounter"]
        console.log()
        counter++
        var EVID = "EV" + ("0".repeat(3 - counter.toString().length)) + counter
        const newEV = { "title": eventTitle, "discription": eventDisc, "date": eventDate, "imgUrl": eventImgUrl }
        db.collection('events').doc(EVID).set(newEV).then(() => {
          db.collection("metadata").doc('eventData').set({ "eventCounter": counter }).then(() => {  setshowForm(false) })


        })
      })
    }
  }

  return (
    <div className="EventPage">
      <div className='MemberToolbar'>
        <div className='eventForm' style={showForm ? { display: '' } : { display: 'none' }}>
          <div className='closeEventButton' onClick={() => setshowForm(false)}>x</div>
          <form onSubmit={AddEvent} className="eventFormform">
            <input placeholder='Title' onChange={e => { seteventTitle(e.target.value) }} required></input>
            <input placeholder='discription' onChange={e => { seteventDisc(e.target.value) }} required></input>
            <input type='datetime-local' placeholder='Date' onChange={e => { seteventDate(new Date(e.target.value)) }} required></input>
            <input type='file' placeholder='img' onChange={readURL}></input>
            <input type='submit' style={{borderRadius: '2px', color: 'white',backgroundColor: 'blueviolet',cursor:'pointer' , padding: '10px', width: '100px',height: '30px',border: 'none'}} />
          </form>
        </div>
        <div className='addEventButton' onClick={() => setshowForm(true)}>+</div>
        <h3>Events</h3>
        <div>
          <input placeholder='Search' />
        </div>
      </div>
      <div className='EventHolder'>
        {eventList.map((el) => { return <EventBox collection={'events'} title={el["title"]} discription={el["discription"]} date={el["date"]} imgUrl={el["imgUrl"]} docID={el["id"]} /> })}
      </div>
    </div>
  );
}

EventPage.propTypes = {};

EventPage.defaultProps = {};

export default EventPage;
