import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './DonationPage.css';
import DonationRow from '../DonationRow/DonationRow';
import { db,storage } from '../../firebaseConfig';
import stateWithDistricts from '../../stateData';
import { useNavigate } from 'react-router-dom';

function DonationPage() {
  const [donationsList, setdonationsList] = useState([])
  const [nameQuery, setnameQuery] = useState("")
  const [showForm, setshowForm] = useState(false)
  const [userImgFile, setuserImgFile] = useState(null)

  const [donorName, setdonorName] = useState("")
  const [donorEmail, setdonorEmail] = useState("")
  const [donorNumber, setdonorNumber] = useState("")
  const [donationAmount, setdonationAmount] = useState("")
  const [donorState, setdonorState] = useState("Maharashtra")
  const [ImgUrl, setImgUrl] = useState("")

  var navigateTo = useNavigate()
  useEffect(() => {
    db.collection('donations').get().then((docs) => {
      var TempList = []
      docs.forEach((doc) => {
        console.table(doc.data())
        TempList.push({
          "name": doc.data()["name"],
          "email": doc.data()["email"],
          "amount": doc.data()["amount"],
          "time": doc.data()["time"],
        })
      })
      setdonationsList(TempList)
    })

  }, [])

  const readURL = event => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImgUrl(reader.result)
      }
    }
    if (event.target.files[0].size > 250000) {
      alert("Image is too big! Must be less Than 250kb");
      setImgUrl()
    }
    else {
      setuserImgFile(event.target.files[0])
      reader.readAsDataURL(event.target.files[0])
    }

  }


  function submitForm(e){
    e.preventDefault()

    if (userImgFile) {
      //If Imgae File is avaiblable then Upload Imge and then Upload data
      const uploadName = 'events/' + 'img' + (new Date()).getTime() + '.' + userImgFile.name.split('.').at(-1)
      const storageRef = storage.ref(uploadName)
      storageRef.put(userImgFile).on('state_changed', (snap) => {
      }, (err) => {
        alert(err.message)
      }
        , async () => {
          const url = await storageRef.getDownloadURL();
          setImgUrl(url)

          db.collection('metadata').doc('donationData').get().then((data) => {
            var counter = data.data()["donationCount"]
            console.log()
            counter++
            var donationID = "DO" + ("0".repeat(3 - counter.toString().length)) + counter
            const donation = {"donationId":donationID,"name":donorName,"email":donorEmail,"state":donorState,"ImgUrl":ImgUrl,"time":new Date().toISOString(),"number":donorNumber,"amount":donationAmount}
            // const newEV = { "title": eventTitle, "discription": eventDisc, "date": eventDate, "imgUrl": eventImgUrl }
            db.collection('donations').doc(donationID).set(donation).then(() => {
              console.log("Donation added");
              console.table(donation)
              db.collection("metadata").doc('donationData').set({ "donationCount": counter }).then(() => { setshowForm(false);navigateTo("/donation")})
            })

          })
        })

    }
  }

  //Searching Function
  function searchDonationbyName(donation) {

    if (nameQuery == "") {
      return true
    }
    else if (donation.name) {
      return donation.name.startsWith(nameQuery)
    }
    return false
  }

  return (
    <div className="DonationPage">
      <div className='DonationRow'>
        <h3>Donations</h3><div className='addEventButton' onClick={() => setshowForm(true)}>+</div>
        <div className='eventForm' style={showForm ? { display: '' } : { display: 'none' }}>
          <div className='closeEventButton' onClick={() => setshowForm(false)}>x</div>
          <form className='DonationForm' onSubmit={submitForm}>
            <input placeholder='Donar Name' required onChange={(e)=>{setdonorName(e.target.value)}}></input>
            <input placeholder='email' required onChange={(e)=>{setdonorEmail(e.target.value)}}></input>
            <input placeholder='mobile number' required onChange={(e)=>{setdonorNumber(e.target.value)}}></input>
            <input placeholder='Donation Amount' required onChange={(e)=>{setdonationAmount(e.target.value)}}></input>
            <input type='file' placeholder='img'  onChange={readURL}></input>
            <select className='memberComponent' onChange={(e) => { setdonorState(e.target.value) }}>{Object.keys(stateWithDistricts).map((st) => { return <option>{st}</option> })}</select>
            <input type='submit' style={{ borderRadius: '2px', color: 'white', backgroundColor: 'blueviolet', cursor: 'pointer', padding: '10px', width: '100px', height: '30px', border: 'none' }} />
          </form>
        </div>
        <div>
          <select>
            <option>Last 30 Days</option>
          </select>
          <input placeholder='Search Donation By Name' onChange={(e) => { setnameQuery(e.target.value) }}></input>
        </div>
      </div>
      <div className='DonationTable'>
        {donationsList.filter(searchDonationbyName).length > 0 ?
          <table>
            <tr>
              <th>Donor</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Date</th>
              {/* <th>Invoice</th> */}
            </tr>
            {donationsList.filter(searchDonationbyName).map((donation) => { return <DonationRow name={donation.name} email={donation.email} amount={donation.amount} time={donation.time} /> })}
          </table> : "No Donationes Found"}
      </div>
    </div>
  );
}

DonationPage.propTypes = {};

DonationPage.defaultProps = {};

export default DonationPage;
