import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './MemberPage.css';
import MemberCard from '../MemberCard/MemberCard';
import { db, storage } from '../../firebaseConfig';
import userImg from '../../assets/user.png'
import stateWithDistricts from '../../stateData';
import { useNavigate } from 'react-router-dom';
import RecieptMeber from '../RecieptMeber/RecieptMeber';

function MemberPage(props) {
  const [memoperList, setmemoperList] = useState([])
  const [seachQuery, setseachQuery] = useState("")
  const [showForm, setshowForm] = useState(false)
  const [memberObj, setmemberObj] = useState(null)


  const [memberState, setmemberState] = useState("Andhra Pradesh")
  const [memberDistrict, setmemberDistrict] = useState()
  const [firstName, setfirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [memberNumber, setmemberNumber] = useState("")
  const [memberCountry, setmemberCountry] = useState("India")
  const [memberProfession, setmemberProfession] = useState("")
  const [memberDOB, setmemberDOB] = useState(null)
  const [phoneNumber, setphoneNumber] = useState("")
  const [memberEmail, setmemberEmail] = useState("")
  const [memberAddr1, setmemberAddr1] = useState("")
  const [memberAddr2, setmemberAddr2] = useState("")
  const [memeberPin, setmemeberPin] = useState("")
  const [formSubmitted, setformSubmitted] = useState(false)
  const [imgUrl, setimgUrl] = useState(userImg)
  const [userImgFile, setuserImgFile] = useState(null)
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const [isLoadind, setisLoadind] = useState(false)

  var navigateTo = useNavigate()
  function submitForm(e) {
    e.preventDefault()
    if (userImgFile) {
      const uploadName = 'img' + (new Date()).getTime() + '.' + userImgFile.name.split('.').at(-1)
      const storageRef = storage.ref(uploadName)
      const member = {
        "name": firstName + ' ' + lastName,
        "mobileNumber": memberNumber,
        "country": memberCountry,
        "profession": memberProfession,
        "DOB": memberDOB,
        "phoneNumber": phoneNumber,
        "email": memberEmail,
        "addr1": memberAddr1,
        "addr2": memberAddr2,
        "pin": memeberPin,
        "post": "Member",
        "onMainPage": false,
        "index": Math.random() * 1000,
        "time": new Date().toString(),
        "invoiceID": 'invoice' + (new Date()).getTime(),
      }
      setisLoadind(true)
      storageRef.put(userImgFile).on('state_changed', (snap) => {
      }, (err) => {
        alert(err.message)
      }
        , async () => {
          const url = await storageRef.getDownloadURL();
          member["imgUrl"] = url

          //Get Metadata table
          db.collection('metadata').doc('membersData').get().then((memberDataDoc) => {
            //Get memberCouter
            var idCounter = memberDataDoc.data()['memberIDCounter']
            var memberCount = memberDataDoc.data()['memberCount']
            var memberCount = memberDataDoc.data()['memberCount']
            //IncrementIt 
            idCounter++
            memberCount++

            //Create new member ID
            var newMemberID = characters.charAt(Math.floor(Math.random() *
              characters.length)) + characters.charAt(Math.floor(Math.random() *
                characters.length)) + idCounter.toString()

            //Upload memnber with new Id
            member['memberID'] = newMemberID
            console.table(member)
            db.collection('formMembers').doc(newMemberID).set(member).then(() => {
              setmemberObj(member)

              //Update memebr Counter in db
              db.collection('metadata').doc('membersData').set({
                'memberCount': memberCount,
                'memberIDCounter': idCounter
              })
              setisLoadind(false)
              setformSubmitted(true)
              // navigateTo("/")
            })

          })

        })
    }
  }


  const readURL = event => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setimgUrl(reader.result)
      }
    }
    if (event.target.files[0].size > 250000) {
      alert("Image is too big! Must be less Than 250kb");
      setimgUrl(userImg)
    }
    else {
      setuserImgFile(event.target.files[0])
      reader.readAsDataURL(event.target.files[0])
    }

  }

  useEffect(() => {
    db.collection(props.collection).get().then((docs) => {
      var memlist = []
      docs.forEach(doc => {
        memlist.push({
          name: doc.data()["name"],
          showOnPage: doc.data()["onMainPage"],
          post: doc.data()["post"],
          imgUrl: doc.data()["imgUrl"],
          id: doc.id,
          state: doc.data()["state"]
        })
      });
      setmemoperList(memlist)
    })

  }, [])


  function searchMembers(member) {
    if (seachQuery === "") {
      return true
    }
    else if (member.name && member.id && member.state && member.post) {
      console.log(seachQuery)
      console.log(member.name)
      console.log(member)
      console.log(member.name.startsWith(seachQuery))
      return member.name.startsWith(seachQuery) || member.id.startsWith(seachQuery) || member.state.startsWith(seachQuery) || member.post.startsWith(seachQuery)
    }
    return false
  } 
  return (
    formSubmitted?<RecieptMeber memObj={memberObj}/> :
    <div className="MemberPage">
      <div className='MemberToolbar'>
        <h3>Members</h3>
        <div className='addEventButton' onClick={() => setshowForm(true)}>+</div>
        <div className='eventForm' style={showForm ? { display: '' } : { display: 'none' }}>
          <div className='closeEventButton' onClick={() => setshowForm(false)}>x</div>
          <form onSubmit={submitForm} className="memberForm">
            <center><label className='memberComponent'></label></center>
            <center><label className='memberComponent'>Add member</label></center>
            <center><label className='memberComponent'></label></center>
            <input className='memberComponent' placeholder='First Name' required onChange={(e) => { setfirstName(e.target.value) }}></input>
            <input className='memberComponent' placeholder='Last Name' required onChange={(e) => { setlastName(e.target.value) }}></input>
            <input className='memberComponent' placeholder='Profession' required onChange={(e) => { setmemberProfession(e.target.value) }}></input>
            <input className='memberComponent' placeholder='Address' required onChange={(e) => { setmemberAddr1(e.target.value) }}></input>
            <input className='memberComponent' placeholder='Address' required onChange={(e) => { setmemberAddr2(e.target.value) }}></input>
            <input className='memberComponent' placeholder='email' required onChange={(e) => { setmemberEmail(e.target.value) }}></input>
            <input className='memberComponent' placeholder='mobile number' required onChange={(e) => { setmemberNumber(e.target.value) }}></input>
            <label className='memberComponent'>State</label>
            <select className='memberComponent' onChange={(e) => { setmemberState(e.target.value) }}>{Object.keys(stateWithDistricts).map((st) => { return <option>{st}</option> })}</select>
            <label className='memberComponent'>Date Of Birth</label>
            <input className='memberComponent' type='date' placeholder='Date' required onChange={(e) => { setmemberDOB(e.target.value) }}></input>
            {/* <img src={userImg}></img> */}
            <label className='memberComponent'>Photo</label>
            <input className='memberComponent' type='file' placeholder='img' onChange={readURL}></input>
            <input className='memberComponent' type='submit' style={{ borderRadius: '2px', color: 'white', backgroundColor: 'blueviolet', cursor: 'pointer', padding: '10px', width: '100px', height: '30px', border: 'none' }} />
          </form>
        </div>
        <div>
          <input placeholder='Search' onChange={(e) => { setseachQuery(e.target.value) }} />
        </div>
      </div>
      <div className='MemberHolder'>
        {memoperList.filter(searchMembers).map((el) => { return <MemberCard key={el["id"]} collection={props.collection} docId={el["id"]} name={el["name"]} post={el["post"]} imgUrl={el["imgUrl"]} showOnPage={el["showOnPage"]} /> })}
      </div>
    </div>
  );
}

MemberPage.propTypes = {};

MemberPage.defaultProps = {};

export default MemberPage;
