import React from 'react';
import PropTypes from 'prop-types';
import './DonationRow.css';

function DonationRow(props) {
  return (
    <tr>
      <td><div className='NameAndEmail'><b>{props.name}</b><email>{props.email}</email></div> </td>
      <td className='amountCol'>₹ {props.amount}</td>
      <td><paid>Paid</paid></td>
      <td className='dateCol'>{props.time.toString().slice(0,10)}</td>
      {/* <td>
        <svg width="35" height="35" className='IncoivceLogo' viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="69" height="69.0001" rx="34.5" fill="#1A5397" />
          <path d="M29.2373 29.2372V25.7288H39.7627V29.2372" stroke="white" stroke-width="2.33898" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M29.2375 39.7626H25.729V35.0846C25.729 33.147 27.2998 31.5762 29.2375 31.5762H39.7629C41.7006 31.5762 43.2714 33.147 43.2714 35.0846V39.7626H39.7629" stroke="white" stroke-width="2.33898" stroke-linecap="round" stroke-linejoin="round" />
          <rect x="29.2373" y="36.2542" width="10.5254" height="7.01695" stroke="white" stroke-width="2.33898" stroke-linecap="round" stroke-linejoin="round" />
        </svg></td> */}
    </tr>
  );
}

DonationRow.propTypes = {};

DonationRow.defaultProps = {};

export default DonationRow;
